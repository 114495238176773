<template>
  <InvoiceListComponent
    title="Invoices"
    :items="invoices"
    :fields="invoicesFields"
  />
</template>

<script>
import InvoiceListComponent from '@/components/InvoiceListComponent';
import { ACTION_FETCH_INVOICES } from '@/store/actions.type';
import { mapGetters } from 'vuex';

export default {
  name: 'Invoices',
  components: {
    InvoiceListComponent,
  },
  data() {
    return {
      invoicesFields: [
        {
          key: 'invoice_no',
          label: 'InvoiceNo',
        },
        {
          key: 'booking_no',
          label: 'BookingNo',
        },
        {
          key: 'issued_by',
          label: 'IssuedBy',
        },
        {
          key: 'invoice_type',
          label: 'Type',
        },
        {
          key: 'pdf_file',
          label: 'PDF',
        },
        {
          key: 'created_at',
          label: 'CreatedDate',
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(ACTION_FETCH_INVOICES, { issued_by: 'comenow' });
  },
  computed: {
    ...mapGetters({
      invoices: 'invoices',
    }),
  },
};
</script>

<style></style>
